.info {
  display: flex;
  flex-direction: column;
  // align-items: center;
  align-items: stretch;
  position: relative;
  overflow-y: auto;
  max-height: 90vh;
  min-width: 320px;
  width: 100%;
  max-width: 435px;
  height: 100%;
  flex-shrink: 1;
  padding: 80px 0 0;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }

  &_blocked {
    overflow-y: hidden;
  }

  @media screen and (min-width: 1000px) {
    // width: auto;
    padding: 0;
  }
}

.heading {
  display: flex;
  justify-content: center;
  padding: 9px 0;
  margin: 17px 23px 0;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.text {
  color: #363942;
  font-size: 13px;
  font-family: Roboto;
  line-height: 19px;
}

.filters {
  width: 29px;
  height: 29px;
  background: url(../../assets/filters_square.svg) center no-repeat;
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding: 0 0 100px;
  gap: 24px;
  // margin: 43px 23px 0;
  overflow-y: scroll; /* Добавлено для включения скролла */
  scrollbar-width: none; /* Убирает скроллбар в Firefox */
  -ms-overflow-style: none; /* Убирает скроллбар в IE и Edge */
}

.cards::-webkit-scrollbar {
  display: none; /* Убирает скроллбар в WebKit (Chrome, Safari, etc.) */
}